<template>
<div class="d-flex">
  <ul id="main-menu-navigation" class="nav navbar-nav">
    <li 
      class="nav-item b-nav-dropdown dropdown dropdown-user"
      :class="{
        'sidebar-group-active active open': isActiveMenuUser,
        'show': isOpenMenuUser,
      }"
      @mouseenter="() => updateGroupOpenMenuUser(true)"
      @mouseleave="() => updateGroupOpenMenuUser(false)"
    >
      <b-link class="nav-link dropdown-user d-flex align-items-center dropdown-user-link">
        <div class="d-lg-flex d-none user-nav">
          <p class="user-name">
            {{ userData ? userData.firstName + ' ' + userData.lastName : "" }}
          </p>
          <span class="user-status">{{ $store.getters.workspaceSelected.socialReason }}</span>
        </div>
        <b-avatar
          size="32"
          :src="userData? userData.avatar : ''"
          class="rounded bg-white text-primary"
        >
          <span class="b-avatar-text">
            <div class="text-primary">{{userData ? userData.firstName.charAt(0) + userData.lastName.charAt(0)  : 'ND'}}</div>
          </span>
        </b-avatar>
      </b-link>
      <ul class="dropdown-menu">
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push('/account')"
        >
          <span>
            <feather-icon
              size="16"
              icon="UserIcon"
              class="mr-50"
            />
            Mon Compte
          </span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push('/admin/admin-workspaces')"
        >
          <span>
            <feather-icon
              size="16"
              icon="HomeIcon"
              class="mr-50"
            />
            Mon Espace de travail
          </span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <span>
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            Déconnexion
          </span>
        </b-dropdown-item>
      </ul>
    </li>
  </ul>
   <vue-element-loading background-color="rgba(24, 54, 109, 1)" color="#f1f1f1" :active="loadingSwitchWorkspace" :is-full-screen="true" :text="'Chargement des données de l\'entreprise '+workspaceName"/>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import VueElementLoading from 'vue-element-loading'
import useHorizontalNavMenuHeaderGroup from '@/@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-header-group/useHorizontalNavMenuHeaderGroup'
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    VueElementLoading
  },
  setup(props) {

    let userData = JSON.parse(localStorage.getItem('login'))?.login?.userInfo
    let workspaceName = ""
    let loadingSwitchWorkspace = false
    let encryptInstitutionId= ""
    const {
      isActive,
      updateIsActive,
      isOpen,
      updateGroupOpen,

      isActiveMenuUser,
      updateIsActiveMenuUser,
      isOpenMenuUser,
      updateGroupOpenMenuUser,
      
      isActiveMenuAdmin,
      updateIsActiveMenuAdmin,
      isOpenMenuAdmin,
      updateGroupOpenMenuAdmin


    } = useHorizontalNavMenuHeaderGroup(props.item)

    return {
      encryptInstitutionId,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,

      isOpenMenuUser,
      isActiveMenuUser,
      updateGroupOpenMenuUser,
      updateIsActiveMenuUser,

      isOpenMenuAdmin,
      isActiveMenuAdmin,
      updateGroupOpenMenuAdmin,
      updateIsActiveMenuAdmin,

      userData,
      workspaceName,
      loadingSwitchWorkspace
    }
  },
  // data() {
  //   return {
  //   }
  // }, 
  async created() {
    this.encryptInstitutionId = this.institutionActive ? this.institutionActive.id : 0
  },
  methods: {
    ...mapActions([
      "updateInstitutionDefault",
    ]),
    verifAdmin(){
      if(!this.userData.roles.includes('Admin')){
        return false
      }else{
        return true
      }
    },
    newInstitutionCheck() {
      if (!this.verifAdmin()) {
        this.$router.push({ name: 'new-institution' })
      } else {
        this.$bvModal
          .msgBoxConfirm('Vous devez être administrateur pour créer une société. Veuillez nous contacter pour créer une nouvelle société.', {
            title:
              'Attention',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Ok',
            cancelTitle: 'Annuler',
            cancelVariant: 'outline-primary',
            hideHeaderClose: true,
            centered: true
          })
      }
    },
    // async onSwitchInstitution(institution){
    //   let _this = this
    //   this.$bvModal
    //     .msgBoxConfirm('Attention, tous vos onglets en cours d\'édition seront fermés.', {
    //       title:
    //         'Souhaitez-vous changer de société par défaut ?',
    //       size: 'sm',
    //       okVariant: 'primary',
    //       okTitle: 'Oui, confirmer',
    //       cancelTitle: 'Annuler',
    //       cancelVariant: 'outline-primary',
    //       hideHeaderClose: true,
    //       centered: true
    //     })
    //     .then(async(value) => {
    //       if (value) {
    //         await this.$store.dispatch('setIsGlobalLoading', true)
    //         await this.$tabs.reset('/')
    //         await this.$store.dispatch("setInstitutionActive", institution);
    //         await this.$tabs.refreshAll()
    //         _this.$nextTick(async () => {
    //           await this.$store.dispatch('setIsGlobalLoading', false)
    //         })

    //         this.encryptInstitutionId = this.institutionActive ? this.institutionActive.id : 0
    //       }
    //     })
    // },
    // async onSwitchWorkspace(id, workspaceName) {
    //   let _this = this
    //   this.$bvModal
    //     .msgBoxConfirm('Attention, tous vos onglets en cours d\'édition seront fermés.', {
    //       title:
    //         'Souhaitez-vous changer d\'entreprise ?',
    //       size: 'sm',
    //       okVariant: 'primary',
    //       okTitle: 'Oui, confirmer',
    //       cancelTitle: 'Annuler',
    //       cancelVariant: 'outline-primary',
    //       hideHeaderClose: true,
    //       centered: true
    //     })
    //     .then(async(value) => {
    //       if (value) {
    //         this.workspaceName=workspaceName;
    //         await this.$store.dispatch('setIsGlobalLoading', true)
    //         this.$tabs.reset('/')
    //         await this.$store.dispatch('setWorkspaceSelected', { workspaceIdSelected:id }).then(async () => {
    //           let institutionsList = await this.$store.dispatch('fetchInstitutionsList', id);
    //           if(institutionsList.length>0){
    //             let defaultInstitution = this.institutionsList.find((el) => el.isDefault);
    //             if (defaultInstitution) {
    //               await this.$store.dispatch("setInstitutionActive", defaultInstitution);
    //             } else {
    //               await this.$store.dispatch("setInstitutionActive", this.institutionsList[0]);
    //             }
    //           }else{
    //             await this.$store.dispatch("setInstitutionActive", InstitutionModel)
    //           }
    //           this.encryptInstitutionId = this.institutionActive ? this.institutionActive.id : 0
    //         })
    //         await this.$tabs.refreshAll()
    //         _this.$nextTick(async () => {
    //           await this.$store.dispatch('setIsGlobalLoading', false)
    //         })
    //       }
    //     })
    // },
    logout() { 
      this.$store.dispatch('logOut')
      this.$router.push('/login')
    },
  },
  computed: {
    ...mapGetters(['institutionsList','isLoadingworkspacesListByUser', 'userInfo', 'workspaceSelected', 'institutionActive']),
    workspacesListByUserLocal() {
      let info = JSON.parse(localStorage.getItem("userInfo"))
      return info.workspaces;
    }
  },
}
</script>
