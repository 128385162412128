<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.devinfluence.fr/"
        target="_blank"
      >DEV INFLUENCE</b-link>
      <span class="d-none d-sm-inline-block">, Tous droits réservés - Version {{version}}</span>
    </span>
    <span class="float-md-right d-none d-md-block">Développé et géré par <a target="_blank" href="https://www.devinfluence.fr/"> DEV INFLUENCE </a>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
// eslint-disable-next-line no-undef
var pjson = require('@/../package.json');

export default {
  components: {
    BLink,
  },
  data() {
    return {
      env: process.env.NODE_ENV,
      version: pjson.version,
    };
  },
}
</script>
